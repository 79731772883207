import { useState, useEffect } from "react";
import { getListOf } from "../helpers/model.helpers";
import {
  populateBaseDesc,
  populateBaseWeight,
  populateBaseColor,
  populateBasePrice,
  populateBaseCube,
} from "../helpers/populate.helpers";
import Pricing from "./pricing";
import "./basePicker.css";

export default function BasePicker({
  updateSubTotals,
  updateQuantities,
  updateBaseData,
  updateCubesTotal,
}) {
  const [list, setList] = useState([]);
  // const [itemData, setItemData] = useState({});
  const [base, setBase] = useState("");
  const [description, setDescription] = useState("");
  const [baseWeight, setBaseWeight] = useState("");
  const [baseColor, setBaseColor] = useState("");
  const [itemPrice, setItemPrice] = useState(0);
  const [itemCubes, setItemCubes] = useState(0);

  console.log(itemPrice);
  console.log(itemCubes);

  async function getBase() {
    try {
      let response = await fetch(
        "https://tempo-order-writer.vercel.app/api/bases/"
      );
      let result = await response.json();
      setList(result);
    } catch (err) {
      alert(err);
    }
  }

  let bases = getListOf(list, "item_number");

  useEffect(() => {
    getBase();
  }, []);

  return (
    <div className="base-table">
      <div className="base-section">
        <h4>Base Selection</h4>
        <div className="base-picker-section">
          <div>
            <label htmlFor="bases">Base Number</label>
            <div className="dropdown detail">
              <select
                name="bases"
                id="bases"
                value={base}
                onChange={(e) => {
                  setBase(e.target.value);
                  setDescription(populateBaseDesc(e.target.value, list));
                  setBaseWeight(populateBaseWeight(e.target.value, list));
                  setBaseColor(populateBaseColor(e.target.value, list));
                  setItemPrice(populateBasePrice(e.target.value, list));
                  setItemCubes(populateBaseCube(e.target.value, list));
                  updateBaseData(
                    e.target.value,
                    populateBaseDesc(e.target.value, list),
                    populateBaseWeight(e.target.value, list),
                    populateBaseColor(e.target.value, list),
                    populateBaseCube(e.target.value, list)
                  );
                }}
              >
                <option value="">Select One</option>
                {bases.map((base, idx) => {
                  return (
                    <option key={base + idx} value={base}>
                      {base}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="base-features">
            <label htmlFor="base-description">Base Description</label>
            <div className="detail">
              <p className="base-description" id="base-description">
                {description}
              </p>
            </div>
          </div>
          <div className="base-features">
            <label htmlFor="base-weight">Base Weight</label>
            <div className="detail">
              <p className="base-description" id="base-weight">
                {baseWeight}
              </p>
            </div>
          </div>
          <div className="base-features">
            <label htmlFor="base-weight">Base Color</label>
            <div className="detail">
              <p className="base-description" id="base-color">
                {baseColor}
              </p>
            </div>
          </div>
          {/* get cube data for bases */}
        </div>
      </div>
      <Pricing
        updateSubTotals={updateSubTotals}
        updateQuantities={updateQuantities}
        updateCubesTotal={updateCubesTotal}
        itemPrice={itemPrice}
        cubeCount={itemCubes}
      />
    </div>
  );
}
