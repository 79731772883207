import "./quoteSheet.css";
import logoBrown from "../assets/tp-brown.png";

export default function QuoteSheet({handleFormChange, order}) {

  return (
    <div className="sheet-container">
      <img src={logoBrown} className="logo-brown" alt="brown Tempo Patio logo" />
      <div className="intro">
      <h1>Tempo Patio Purchase Order</h1>
      <p>Thank you for your interest in Tempo Patio. To use the form, please follow Steps 1-3:</p>
      <ol>
        <li>Fill in your company and contact data. Fields marked with "•" are required.</li>
        <li>Select the desired model and base parts from the dropdown menus.</li>
        <li>Download the Purchase Order to PDF and email to Robin Smith <strong>[rsmith@tempoleisure.com]</strong>.</li>
      </ol>
      </div>
      <form className="form-meta-info" action="">
        <div className="order-number-container">
          <p>Order Number: <strong>{order}</strong></p>
        </div>
        <div className="form-field col">
          <label htmlFor="order__date">Date •</label>
          <input type="date" name="order__date" id="order__date" onChange={handleFormChange} required/>
          <label htmlFor="co__name">Company/Customer Name •</label>
          <input type="text" name="co__name" id="co__name" autoCapitalize="words" onChange={handleFormChange} required/>
          <label htmlFor="address">Address •</label>
          <input type="text" name="address" id="address" autoComplete="street-address" onChange={handleFormChange} required/>
          <label htmlFor="city">City •</label>
          <input type="text" name="city" id="city" onChange={handleFormChange} required/>
          <label htmlFor="state">State •</label>
          <input type="text" name="state" id="state" onChange={handleFormChange} required/>
          <label htmlFor="zip">ZIP •</label>
          <input type="text" name="zip" id="zip" onChange={handleFormChange} required/>
          <label htmlFor="contact">Contact Name •</label>
          <input type="text" name="contact" id="contact" onChange={handleFormChange} required/>
          <label htmlFor="phone">Phone •</label>
          <input type="tel" name="phone" id="phone" autoComplete="tel-national" onChange={handleFormChange} required/>
          <label htmlFor="email">Email •</label>
          <input type="email" name="email" id="email" autoComplete="email" onChange={handleFormChange} required/>
        </div>
        <div className="form-field col">
          <label htmlFor="account__rep">Account Rep. •</label>
          <input type="text" name="account__rep" id="account__rep" onChange={handleFormChange} autoCapitalize="words" required/>
          <label htmlFor="ship__date">Requested Ship Date •</label>
          <input type="date" name="ship__date" id="ship__date" onChange={handleFormChange} required/>
        </div>
        <div className="form-field col">
          <label htmlFor="order__notes">Notes</label>
          <textarea type="text" name="order__notes" id="order__notes" onChange={handleFormChange}></textarea>
        </div>
      </form>
    </div>
  );
}

