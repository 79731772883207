import { useState } from "react";
import { getExtCost } from "../helpers/pricing.helpers";
import "./pricing.css";

export default function Pricing({
  updateSubTotals,
  updateQuantities,
  updateCubesTotal,
  itemPrice,
  cubeCount,
}) {
  const [quantity, setQuantity] = useState("");

  //Derived State
  const extCost = getExtCost(quantity, itemPrice);
  const extCubes = cubeCount * quantity;

  return (
    <div className="pricing-container">
      <div className="pricing-section">
        <label htmlFor="quantity">Quantity</label>
        <input
          type="text"
          name="quantity"
          id="quantity"
          value={quantity}
          onChange={(e) => {
            setQuantity(Number(e.target.value));
          }}
        />
      </div>
      <div className="pricing-section">
        <label htmlFor="ea-cost">Cost (ea)</label>
        <input
          type="text"
          name="ea-cost"
          id="ea-cost"
          readOnly
          value={itemPrice}
        />
      </div>
      <div className="pricing-section">
        <label htmlFor="ext-cost">Extended Cost</label>
        <input
          type="text"
          name="ext-cost"
          id="ext-cost"
          value={Number(extCost).toFixed(2)}
          readOnly
        />
      </div>
      <div className="pricing-section">
        <label htmlFor="cubes">Cube Subtotal (ft³)</label>
        <input
          type="text"
          name="cubes"
          id="cubes"
          value={Number(extCubes).toFixed(2)}
          readOnly
        />
      </div>
      <button
        className="confirm-btn"
        onClick={() => {
          updateSubTotals(extCost);
          updateQuantities(quantity);
          updateCubesTotal(extCubes);
        }}
      >
        Confirm
      </button>
    </div>
  );
}
