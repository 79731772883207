import React, { useState, useEffect, useMemo } from "react";
import { getListOf } from "../helpers/model.helpers";
import {
  populateAbv,
  populateDesc
} from "../helpers/populate.helpers";
import { validate } from "../helpers/validation.helpers";
// import { ventAbbr } from "../helpers/vent.helpers";
import Pricing from "./pricing";
import "./modelPicker.css";

// reevaluate need for populate helpers

export default function ModelPicker({
  updateSubTotals,
  updateQuantities,
  updateModelData,
  updateCubesTotal,
}) {
  const [list, setList] = useState([]);
  const [colorNums, setColorNums] = useState({});
  const [itemData, setItemData] = useState({});
  const [selectColors, setSelectColors] = useState([]);
  const [modelNumber, setModelNumber] = useState("");
  const [modelDesc, setModelDesc] = useState("");
  const [ventType, setVentType] = useState("");
  const [frameFinish, setFrameFinish] = useState("");
  const [frameAbv, setFrameAbv] = useState("");
  const [fabricBrand, setFabricBrand] = useState("");
  const [fabricColor, setFabricColor] = useState("");
  const [fabricNumber, setFabricNumber] = useState("");
  const [itemPrice, setItemPrice] = useState(0);
  const [itemCubes, setItemCubes] = useState(0);

  console.log(itemCubes);

  async function getModel() {
    try {
      let response = await fetch("https://tempo-order-writer.vercel.app/api/models", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let result = await response.json();
      setList(result);

      //Begin extracting data and store it for more convenient (fast) access later
      const fabricData = {};
      const itemData = {};

      for (let i = 0; i < result.length; i++) {
        //Fabric Data
        if (!fabricData[result[i].fabric_brand]) {
          fabricData[result[i].fabric_brand] = {};
        }
        fabricData[result[i].fabric_brand][result[i].fabric_color] =
          result[i].fabric_number;

        //Item Data -> use for bases
        itemData[result[i].item_number] = {
          item_price: result[i].item_price,
          item_cube: result[i].item_cube,
        };
      }
      setItemData(itemData);
      setColorNums(fabricData);
    } catch (err) {
      console.error(err);
    }
  }

  /*
    useMemo isn't even the best solution to this. 
    Really, all of these `getListOf` calls should only happen once, after the fetch comes back.
    They don't need to run on every render of the component. 
    But useMemo essentially fixes the problem without having to rearrange any code
  */
  let models = useMemo(() => getListOf(list, "model"), [list]);
  let vents = useMemo(() => getListOf(list, "vent_type"), [list]);
  let finishes = useMemo(() => getListOf(list, "frame_color"), [list]);
  let fabricBrands = useMemo(() => getListOf(list, "fabric_brand"), [list]);
  let valids = useMemo(() => getListOf(list, "item_number"), [list]);
  let itemNumber = [modelNumber, ventType, frameAbv, fabricNumber].join("-");
  let valid = validate(valids, itemNumber);

  // use complete itemNumber and validModelPush (valid) to get price
  const validModelPush = (tempItemNumber) => {
    if (validate(valids, tempItemNumber)) {
      updateModelData(tempItemNumber, modelDesc);
      //Use itemData to get the correct itemPrice and then setItemPrice(blah blah)
      setItemPrice(itemData[tempItemNumber].item_price);
      setItemCubes(itemData[tempItemNumber].item_cube);
    }
  };

  useEffect(() => {
    getModel();
  }, []);

  useEffect(() => {
    validModelPush(itemNumber);
  }, [itemNumber]);

  // need to format all number outputs
  // update readOnly inputs to vibe less interaction

  return (
    <div className="model-table">
      <form className="model-list" action="">
        <div className="picker-section">
          <h4>Model Selection</h4>
          <label htmlFor="model-number">Model Number</label>
          <div className="dropdown detail">
            <select
              name="model-number"
              id="model-number"
              value={modelNumber}
              onChange={(e) => {
                setModelNumber(e.target.value);
                setModelDesc(populateDesc(e.target.value, list));
              }}
            >
              <option value="">Select One</option>
              {models.map((model, idx) => {
                return (
                  <option key={model + idx} value={model}>
                    {model}
                  </option>
                );
              })}
            </select>
          </div>
          <label htmlFor="description">Item Description</label>
          <div className="detail">
            <p className="model-description">{modelDesc}</p>
          </div>
          <label htmlFor="vent-type">Vent Type</label>
          <div className="dropdown detail">
            <select
              name="vent-type"
              id="vent-type"
              value={ventType}
              onChange={(e) => {
                setVentType(e.target.value);
              }}
            >
              <option value="">Select One</option>
              {vents.map((vent, idx) => {
                return (
                  <option key={vent + idx} value={vent}>
                    {vent}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="picker-section">
          <h4>Frame Selection</h4>
          <label htmlFor="frame-finish">Frame Finish</label>
          <div className="dropdown detail">
            <select
              name="frame-finish"
              id="frame-finish"
              value={frameFinish}
              onChange={(e) => {
                setFrameFinish(e.target.value);
                setFrameAbv(populateAbv(e.target.value, list));
              }}
            >
              <option value="">Select One</option>
              {finishes.map((finish, idx) => {
                return (
                  <option key={finish + idx} value={finish}>
                    {finish}
                  </option>
                );
              })}
            </select>
          </div>
          <label htmlFor="frame-abv">Frame Abv.</label>
          <div className="detail">
            <input
              type="text"
              name="frame-abv"
              id="frame-abv"
              value={frameAbv}
              readOnly
            />
          </div>
        </div>
        <div className="picker-section fabric">
          <h4>Fabric Selection</h4>
          <label htmlFor="fabric-brand">Fabric Brand</label>
          <div className="dropdown detail">
            <select
              name="fabric-brand"
              id="fabric-brand"
              value={fabricBrand}
              onChange={(e) => {
                setFabricBrand(e.target.value);
                if (e.target.value) {
                  setSelectColors(Object.keys(colorNums[e.target.value]));
                } else {
                  setFabricColor("");
                  setFabricNumber("");
                }
              }}
            >
              <option value="">Select One</option>
              {fabricBrands.map((fabricBrand, idx) => {
                return (
                  <option key={fabricBrand + idx} value={fabricBrand}>
                    {fabricBrand}
                  </option>
                );
              })}
            </select>
          </div>
          <label htmlFor="fabric-brand-color">Fabric Color</label>
          <div className="dropdown detail">
            <select
              name="fabric-brand-color"
              id="fabric-brand-color"
              disabled={fabricBrand === ""}
              value={fabricColor}
              onChange={(e) => {
                setFabricColor(e.target.value);
                setFabricNumber(colorNums[fabricBrand][e.target.value]);
              }}
            >
              <option value="">Select One</option>
              {selectColors.map((color, idx) => {
                return (
                  <option key={color + idx} value={color}>
                    {color}
                  </option>
                );
              })}
            </select>
          </div>
            <label htmlFor="fabric-number">Fabric Number</label>
          <div className="detail">
            <input
              type="text"
              name="fabric-number"
              id="fabric-number"
              value={fabricNumber}
              readOnly
            />
          </div>
        </div>
        <div className="model-validation">
          <h4>Item Validation</h4>
          <label htmlFor="item-number">Item Number</label>
          <input
            type="text"
            name="item-number"
            id="item-number"
            value={itemNumber}
            readOnly
          />
          <label htmlFor="model-valid">Valid Model</label>
          <input
            type="text"
            name="model-valid"
            id="model-valid"
            value={valid ? "Valid" : "Invalid"}
            readOnly
          />
          <p id="validity-note">
            Note: Model will show as <strong>Invalid</strong> until all
            selections are made. If <strong>Invalid</strong> is still shown,
            please adjust your selections.
          </p>
        </div>
      </form>
      <Pricing
        updateSubTotals={updateSubTotals}
        updateQuantities={updateQuantities}
        updateCubesTotal={updateCubesTotal}
        itemPrice={itemPrice}
        cubeCount={itemCubes}
      />
    </div>
  );
}
