import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import OrderReview from "./orderReview";

export default function PrintOrder() {
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div>
            <OrderReview ref={componentRef} handleOrderPrint={(e) => handlePrint(e)} />
        </div>
    );
}